<template>
  <div class="jumbotron-wrapper image">
    <div class="jumbotron container-fluid ps-0 p-auto" style="height: 600px">
      <div class="animation cover-text mt-5">
        <h1>页面不存在</h1>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PageNotFound"
}
</script>

<style>

</style>
